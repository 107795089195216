import { FormGroup } from '@angular/forms';
import {
  FileDTO,
  FileDetailsDTO,
  convertFormToFileFormDTOArray,
  convertResponseToFileDetailsDTO,
} from '../../../../shared/dto/file.dto';
import {
  UserMinimalDTO,
  convertResponseToUserMinimalDTO,
} from '../../../organization/users/dto/user.dto';

export interface ActionPlanStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  status: string;
}

export interface ActionPlanStatusDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  type: string;
  status: string;
}

export interface ActionPlanStatusUpdateFormDTO {
  id?: number;
  action_plan_status_id: number;
  percentage: number;
  comment: string;
  documents: FileDetailsDTO[];
}

export interface ActionPlanCurrentStatusDetailsDTO {
  id: number;
  action_plan_status: ActionPlanStatusDetailsDTO | null;
  comment: string;
  percentage: number;
  documents: FileDetailsDTO[];
  updated_by: UserMinimalDTO | null;
  updated_at: string;
}

export function convertFormToActionPlanStatusUpdateFormDTO(
  formGroup: FormGroup
): ActionPlanStatusUpdateFormDTO {
  return {
    id: formGroup.get('id')?.value,
    percentage: parseInt(formGroup.get('Percentage')?.value, 10),
    action_plan_status_id: parseInt(formGroup.get('Status')?.value, 10),
    comment: formGroup.get('Comment')?.value,
    documents: convertFormToFileFormDTOArray(formGroup),
  };
}

export function convertResponseToActionPlanStatuDetailsDTO(
  response: any,
  lang: string = 'en'
): ActionPlanStatusDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    color_code: response.color_code,
    type: response.type,
    status: response.status,
  };
}

export function convertResponseToActionPlanCurrentStatusDetailsDTO(
  response: any,
  lang: string = 'en'
): ActionPlanCurrentStatusDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    action_plan_status: convertResponseToActionPlanStatuDetailsDTO(
      response.action_plan_status
    ),
    comment: response.comment,
    percentage: response.percentage,
    documents: response.documents ? response.documents.map((item: any) => ({
      document: convertResponseToFileDetailsDTO(response.documents),
    })) : [],
    updated_by: response.created_by
      ? convertResponseToUserMinimalDTO(response.created_by, 'en')
      : null,
    updated_at: response.created_at,
  };
}
