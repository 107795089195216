import { FormSection } from '../models/form.config';

export const actionPlanFormConfig: FormSection[] = [
  {
    key: 'action_plan',
    title: '',
    fields: [
      {
        id: 'title',
        label: 'Title',
        formControlName: 'Title',
        type: 'text',
        placeholder: 'Enter the action plan title',
        size: 12,
        isInfoIcon: true,
        validators: ['required'],
      },

      {
        id: 'description',
        label: 'Description',
        formControlName: 'Description',
        type: 'textarea',
        placeholder: 'Provide a detailed description of the action plan',
        size: 12,
        rows: 5,
        isInfoIcon: true,
        validators: [],
      },
      {
        id: 'responsibleUser',
        label: 'Responsible User',
        formControlName: 'ResponsibleUser',
        type: 'autocomplete-static',
        placeholder: 'Select the responsible user',
        size: 12,
        isInfoIcon: false,
        validators: ['required'],
      },
      {
        id: 'severityLevel',
        label: 'Severity Level',
        formControlName: 'SeverityLevel',
        type: 'autocomplete-static',
        placeholder: 'Select the severity level',
        size: 6,
        isInfoIcon: false,
        validators: ['required'],
      },
      {
        id: 'target',
        label: 'Target',
        formControlName: 'TargetDate',
        type: 'datepicker',
        placeholder: 'Select the completion date',
        size: 6,
        isInfoIcon: true,
        validators: ['required'],
      },
      {
        id: 'actionPlanStatus',
        label: 'Action Plan Status',
        formControlName: 'ActionPlanStatus',
        type: 'autocomplete-static',
        placeholder: 'Select the action plan status',
        size: 6,
        isInfoIcon: true,
        validators: ['required'],
      },
      {
        id: 'percentage',
        label: 'Percentage',
        formControlName: 'Percentage',
        type: 'text',
        placeholder: '% of completion',
        size: 6,
        isInfoIcon: false,
        validators: [],
      }
    ],
  },
];
