import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../dto/table.dto';

export const actionPlanListConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Para,
    field: 'description',
    header: 'Description',
    sortable: true,
    size: DataCellSize.Para,
    isEnable: false,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'severity_level',
    header: 'Severity',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.User,
    field: 'responsible_user',
    header: 'Responsible User',
    sortable: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'target_date',
    header: 'Target Date',
    sortable: true,
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Text,
    field: 'percentage',
    header: 'Percentage',
    sortable: true,
    size: DataCellSize.Number,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'action_plan_status',
    header: 'Status',
    sortable: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
];
